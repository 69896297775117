import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";
import { serialize } from "object-to-formdata";

const initialState = {
  leaveTypes: [],
  AddleaveTypesStatus: "idle",
  AddleaveTypesError: null,
  leaveTypeStatus: "idle",
  leaveTypeError: null,

  status: "idle",
  error: null,

  getOneLeaveTypeStatus: "idle",
  getOneLeaveTypeSuccess: "",
  getOneLeaveTypeError: "",

  deleteLeaveTypeStatus: "idle",
  deleteLeaveTypeSuccess: "",
  deleteLeaveTypeError: "",

  updateLeaveTypeStatus: "idle",
  updateLeaveTypeSuccess: "",
  updateLeaveTypeError: "",
};

export const fetchAlltype = createAsyncThunk(
  "leave-types/fetchAlltype",
  async () => {
    let data;
    try {
      const response = await axios.get(`/leave-types`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const createLeaveType = createAsyncThunk(
  "leave-types/createLeaveType",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/leave-types`, serialize(body));
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(fetchAlltype());
        return { data: data, message: "Leave type is successfully submitted" };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : err);
    }
  }
);

export const getOneLeaveType = createAsyncThunk(
  "leave-types/getOneLeaveType",
  async (id) => {
    let data;
    try {
      const response = await axios.get(`/leave-types/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const deleteLeaveType = createAsyncThunk(
  "leave-types/deleteLeaveType",
  async (id, thunkAPI) => {
    let data;
    try {
      const response = await axios.patch(`/leave-types/delete/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(fetchAlltype());

        return data;
      }

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const updateLeaveTypes = createAsyncThunk(
  "leave-types/updateLeaveTypes",
  async (body, thunkAPI) => {
    const { id, name, color, img } = body;
    let data;
    try {
      const response = await axios.patch(
        `/leave-types/${id}`,
        serialize({ color, name, img }, { indices: true })
      );
      data = await response.data;

      if ((response.status = 200)) {
        thunkAPI.dispatch(fetchAlltype());
        return {
          data: data,
          message: "Leave type has been successfully submitted.",
        };
      }

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "leaveTypes",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAlltype.pending]: (state) => {
      state.leaveTypeStatus = "loading";
    },
    [fetchAlltype.fulfilled]: (state, action) => {
      state.leaveTypeStatus = "succeeded";
      state.leaveTypes = action.payload;
    },
    [fetchAlltype.rejected]: (state, action) => {
      state.leaveTypeStatus = "failed";
      state.leaveTypeError = action.error.message;
    },

    [createLeaveType.pending]: (state) => {
      state.AddleaveTypesStatus = "loading";
    },

    [createLeaveType.fulfilled]: (state) => {
      state.AddleaveTypesStatus = "succeeded";
    },

    [createLeaveType.rejected]: (state, action) => {
      state.AddleaveTypesStatus = "failed";
      state.AddleaveTypesError = action.error.message;
    },

    [getOneLeaveType.pending]: (state) => {
      state.getOneLeaveTypeStatus = "loading";
    },
    [getOneLeaveType.fulfilled]: (state, action) => {
      state.getOneLeaveTypeStatus = "succeeded";
      state.getOneLeaveTypeSuccess = action.payload.message;
    },
    [getOneLeaveType.rejected]: (state, action) => {
      state.getOneLeaveTypeStatus = "failed";
      state.getOneLeaveTypeError = action.error.message;
    },

    [deleteLeaveType.pending]: (state) => {
      state.deleteLeaveTypeStatus = "loading";
    },
    [deleteLeaveType.fulfilled]: (state, action) => {
      state.deleteLeaveTypeStatus = "succeeded";
      state.deleteLeaveTypeSuccess = action.payload.message;
    },
    [deleteLeaveType.rejected]: (state, action) => {
      state.deleteLeaveTypeStatus = "failed";
      state.deleteLeaveTypeError = action.error.message;
    },

    [updateLeaveTypes.pending]: (state) => {
      state.updateLeaveTypeStatus = "loading";
    },
    [updateLeaveTypes.fulfilled]: (state, action) => {
      state.updateLeaveTypeStatus = "succeeded";
      state.leaveTypes = action.payload.message;
    },
    [updateLeaveTypes.rejected]: (state, action) => {
      state.updateLeaveTypeStatus = "failed";
      state.updateLeaveTypeError = action.error.message;
    },
  },
});

export const reducer = slice.reducer;
